button {background-color: transparent;}

a{text-align: center;}

p#status {
  color:rgba(17, 14, 29, 0.6);
  min-height: 40px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


#txtNumber[readonly] {
  background-color: rgba(59, 51, 105, 0.1);
  cursor: inherit;
}

.addressAuthorized{
  float: right;
  font-size: 14px;
  color:rgba(17, 14, 29, 0.6);
}
.addressNotAuthorized{
  float: right;
  font-size: 14px;
  color:red;
  text-align: justify;
  text-transform: none !important;
  line-height: 1.2;
}
div.divCountDown{
  display: flex;
  justify-content: center;
}
div.divCountDown div{
  display: flex;
  padding:5px 20px;
  text-align: center;
  color:#fff;
  font-size: 12px;
}

div.divCountDown span{
  display: contents;
  font-size: 30px;
}
div.divCountDownTitle{
  color: #fff;
  font-size: 13px;
  text-align: center;
  padding-bottom: 5px;
  text-transform: uppercase;
}
.mint-info-status-first{
  padding-top: 0px !important ;
}

@media(max-width:400px) {
  .w-video{
    text-align: left;
  }
  div.divCountDown div {
    padding: 5px 5px;
    color: #fff;
    font-size: 10px;
}
div.divCountDown span {
  font-size: 25px;
}
.btn {
  padding: 8px 14px !important;
}
#walletButton{
  font-size: 12px;
}
}
.headerNotifications {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  background-image: linear-gradient(62deg,#eda01f,#f7dc4d);
  z-index: 9999;
}
.headerNotifications h4{
  font-size: 0.9rem;
  text-align: center;
  margin-top:2px;
  margin-bottom: 2px;
}
.navbar{
  top:22px !important;
}

@media(max-width: 410px)
{
  .navbar{
      top:40px !important;
    }
}

#WEB3_CONNECT_MODAL_ID{
  position: absolute;
  z-index: 9999999;
}